.Certifications {
  padding: 40px 0;
}

.Certifications h2 {
  font-size: 32px;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.dark .Certifications h2 {
  color: #a4c2f4;
}

.light .Certifications h2 {
  color: #5E81AC;
}

.certification-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.certification {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certification img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.certification p {
  font-size: 16px;
  text-align: center;
  transition: color 0.3s;
}

.dark .certification p {
  color: #d1d1d1;
}

.light .certification p {
  color: #333333;
}
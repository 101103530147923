.Skills {
  padding: 40px 0;
}

.Skills h2 {
  font-size: 32px;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.dark .Skills h2 {
  color: #a4c2f4;
}

.light .Skills h2 {
  color: #5E81AC;
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.skill p {
  font-size: 16px;
  transition: color 0.3s;
}

.dark .skill p {
  color: #d1d1d1;
}

.light .skill p {
  color: #333333;
}
.Projects {
  padding: 40px 0;
}

.Projects h2 {
  font-size: 32px;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.dark .Projects h2 {
  color: #a4c2f4;
}

.light .Projects h2 {
  color: #5E81AC;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.project {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.dark .project {
  background-color: #2c2c2e;
  color: #d1d1d1;
}

.light .project {
  background-color: #ECEFF4;
  color: #333333;
}

.project h3 {
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s;
}

.dark .project h3 {
  color: #a4c2f4;
}

.light .project h3 {
  color: #5E81AC;
}

.project h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 0.3s, background-color 0.3s;
}

.dark .project h3::after {
  background-color: #a4c2f4;
}

.light .project h3::after {
  background-color: #5E81AC;
}

.project h3:hover::after {
  transform: scaleX(1);
}

.project p {
  font-size: 16px;
  line-height: 1.6;
}
:root {
  /* Dark theme colors */
  --bg-color-dark: #2E3440;
  --text-color-dark: #d1d1d1;
  --link-color-dark: #a4c2f4;

  /* Light theme colors */
  --bg-color-light: #E5E9F0;
  --text-color-light: #333333;
  --link-color-light: #5E81AC;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

body.dark {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark);
}

body.light {
  background-color: var(--bg-color-light);
  color: var(--text-color-light);
}

.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
}

nav {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100;
  padding-right: 20px;
}

nav::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  z-index: -1;
  transition: background-color 0.3s;
}

.dark nav::after {
  background-color: var(--bg-color-dark);
}

.light nav::after {
  background-color: #D8DEE9;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  text-decoration: none;
  font-size: 18px;
  position: relative;
  transition: color 0.3s;
}

.dark nav ul li a {
  color: var(--link-color-dark);
}

.light nav ul li a {
  color: var(--link-color-light);
}

nav ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 0.3s, background-color 0.3s;
}

.dark nav ul li a::after {
  background-color: var(--link-color-dark);
}

.light nav ul li a::after {
  background-color: var(--link-color-light);
}

nav ul li a:hover::after {
  transform: scaleX(1);
}

.theme-toggle {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
}

.dark .theme-toggle {
  color: var(--text-color-dark);
  background-color: rgba(255, 255, 255, 0.1);
}

.light .theme-toggle {
  color: var(--text-color-light);
  background-color: rgba(0, 0, 0, 0.1);
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.dark .theme-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.light .theme-toggle:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.Contact {
  max-width: 600px;
  margin: 40px auto 0;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.dark .Contact {
  background-color: #2c2c2e;
  color: #d1d1d1;
}

.light .Contact {
  background-color: #ECEFF4;
  color: #333333;
}

.Contact h2 {
  font-size: 32px;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.dark .Contact h2 {
  color: #a4c2f4;
}

.light .Contact h2 {
  color: #5E81AC;
}

.Contact p {
  margin-bottom: 10px;
}

.Contact a {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.dark .Contact a {
  background-color: #a4c2f4;
  color: #1c1c1e;
}

.light .Contact a {
  background-color: #5E81AC;
  color: #ffffff;
}

.Contact a:hover {
  opacity: 0.9;
}

.resume-section {
  margin-top: 40px;
}

.resume-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
  transition: color 0.3s;
}

.dark .resume-section h3 {
  color: #a4c2f4;
}

.light .resume-section h3 {
  color: #5E81AC;
}

.resume-section iframe {
  border: none;
  margin-bottom: 20px;
}

.resume-section a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.dark .resume-section a {
  background-color: #5E81AC;
  color: #1c1c1e;
}

.light .resume-section a {
  background-color: #5E81AC;
  color: #ffffff;
}

.resume-section a:hover {
  opacity: 0.9;
}